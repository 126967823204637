@tailwind base;
@tailwind components;
@tailwind utilities;

.wave {
    animation: waving 2s infinite ease-in-out;
}

@keyframes waving {
    0% {
        transform: rotate(-15deg);
    }

    50% {
        transform: rotate(15deg);
    }

    100% {
        transform: rotate(-15deg);
    }
}

@layer components {
    html {
        -moz-text-size-adjust: 100%;
        -webkit-text-size-adjust: 100%;
        text-size-adjust: 100%;

        @apply bg-custom-light dark:bg-custom-dark;
    }

    h1 {
        @apply text-6xl font-semibold text-center text-custom-dark
        flex flex-col
        w-full

        lg:text-left lg:w-auto

        dark:text-custom-light;
    }

    h2 {
        @apply pb-4
        text-7xl font-semibold text-custom-dark
        flex justify-center
        
        dark:text-custom-light;
    }

    h3 {
        @apply text-6xl font-bold text-custom-dark text-center
        
        lg:text-3xl

        dark:text-custom-light;
    }

    h4 {
        @apply text-5xl text-custom-dark text-center
        
        lg:text-2xl

        dark:text-custom-light;
    }

    h5 {
        @apply text-4xl text-custom-dark text-center
        
        lg:text-lg

        dark:text-custom-light;
    }

    p {
        @apply text-5xl text-left font-light text-custom-dark
        flex justify-start items-center
        
        lg:text-2xl

        dark:text-custom-light;
    }

    ul {
        @apply list-inside;
    }

    li {
        @apply text-5xl text-left font-light text-custom-dark
        
        lg:text-2xl

        dark:text-custom-light;
    }

    a {
        @apply p-4 w-2/3
        flex justify-center items-center
        text-6xl text-custom-light
        bg-custom-color
        border-custom-thin-line border-custom-light
        rounded-full

        lg:border-custom-thin-line-lg lg:text-3xl

        hover:bg-custom-light hover:text-custom-color hover:border-custom-color hover:dark:bg-custom-dark
        
        dark:border-custom-dark;
    }

    footer {
        @apply flex justify-center items-center
        text-custom-light text-3xl;
    }

    .custom-border {
        @apply border-custom-thin-line border-custom-dark
        custom-shadow

        lg:border-custom-thin-line-lg

        dark:border-custom-light;
    }

    .custom-shadow {
        @apply shadow-md shadow-custom-dark/25
        
        dark:shadow-custom-light/25;
    }

    .horizontal-line {
        @apply bg-custom-color rounded-custom-border-radius custom-shadow h-custom-thin-line-lg;
    }

    .custom-rounded {
        @apply rounded-3xl;
    }
}